import { PrimaryButton } from "@/Components/Buttons"
import Card from "@/Components/Cards/Card"
import ClientContentContainer from "@/Components/Containers/ClientContentContainer"
import ServiceKindIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/ServiceKindIcon"

const Landing = () => {
  return (
    <>
      <div className="bg-[#d5e7f2]">
        <ClientContentContainer>
          <div className="mx-auto flex max-w-6xl items-center justify-between space-x-8">
            <div className="space-y-4">
              <h1 className="font-serif text-5xl font-medium text-cerulean-900">
                How can WHRC help you today?
              </h1>
              <p className="max-w-sm">
                Connect with vetted tools and resources to help you achieve your
                homeownership dream and succeed as a homeowner.
              </p>
              <PrimaryButton href={route("onboarding.homebuyer.show")}>
                Get Started
              </PrimaryButton>
            </div>
            <div>
              <svg
                className="max-w-sm xl:max-w-md"
                width="646"
                height="379"
                viewBox="0 0 646 379"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2512_1838)">
                  <path
                    d="M133.637 323.694L45.7692 324.289L43.8495 151.971H135.657L133.637 323.694Z"
                    fill="white"
                  />
                  <path
                    d="M180.899 325.378L139.878 325.839L138.98 191.825H181.842L180.899 325.378Z"
                    fill="white"
                  />
                  <path
                    d="M368.017 359.842H262.031L255.609 294.508L314.064 254.082L373.81 292.633L368.017 359.842Z"
                    fill="white"
                  />
                  <path
                    d="M510.354 227.366L211.76 226.715V90.8028L243.945 55.9236L283.237 81.033L320.654 58.0235L359.99 82.4929L399.237 58.0235L440.403 82.4929L472.769 59.0005L510.354 92.2514V227.366Z"
                    fill="white"
                  />
                  <path
                    d="M433.511 316.069C443.307 316.069 451.248 306.466 451.248 294.621C451.248 282.775 443.307 273.172 433.511 273.172C423.715 273.172 415.773 282.775 415.773 294.621C415.773 306.466 423.715 316.069 433.511 316.069Z"
                    fill="white"
                  />
                  <path
                    d="M210.536 288.04C220.332 288.04 228.273 278.437 228.273 266.591C228.273 254.746 220.332 245.143 210.536 245.143C200.74 245.143 192.799 254.746 192.799 266.591C192.799 278.437 200.74 288.04 210.536 288.04Z"
                    fill="white"
                  />
                  <path
                    d="M568.135 131.533C586.469 131.533 601.331 121.93 601.331 110.084C601.331 98.2383 586.469 88.6355 568.135 88.6355C549.801 88.6355 534.939 98.2383 534.939 110.084C534.939 121.93 549.801 131.533 568.135 131.533Z"
                    fill="white"
                  />
                  <path
                    d="M53.3356 109.085C71.6692 109.085 86.5315 99.4818 86.5315 87.636C86.5315 75.7903 71.6692 66.1874 53.3356 66.1874C35.0021 66.1874 20.1398 75.7903 20.1398 87.636C20.1398 99.4818 35.0021 109.085 53.3356 109.085Z"
                    fill="white"
                  />
                  <path
                    d="M149.892 77.5069C160.228 77.5069 168.606 69.1258 168.606 58.7871C168.606 48.4485 160.228 40.0673 149.892 40.0673C139.557 40.0673 131.178 48.4485 131.178 58.7871C131.178 69.1258 139.557 77.5069 149.892 77.5069Z"
                    fill="white"
                  />
                  <path
                    d="M241.464 37.4396C251.8 37.4396 260.178 29.0584 260.178 18.7198C260.178 8.38114 251.8 0 241.464 0C231.129 0 222.75 8.38114 222.75 18.7198C222.75 29.0584 231.129 37.4396 241.464 37.4396Z"
                    fill="white"
                  />
                  <path
                    d="M284.809 229.006H209.627C208.717 229.006 207.988 228.276 207.988 227.366V88.4333C207.988 87.928 208.223 87.4563 208.616 87.1419L246.213 57.7091C246.808 57.2486 247.639 57.2486 248.234 57.7091L285.83 87.1419C286.223 87.4563 286.459 87.928 286.459 88.4333V227.366C286.459 228.276 285.729 229.006 284.82 229.006H284.809ZM211.266 225.727H283.169V89.2306L247.212 61.0779L211.254 89.2306V225.727H211.266Z"
                    fill="#367D79"
                  />
                  <path
                    d="M256.316 229.006C255.407 229.006 254.677 228.276 254.677 227.366V199.573H239.758V227.366C239.758 228.276 239.028 229.006 238.119 229.006C237.209 229.006 236.48 228.276 236.48 227.366V197.933C236.48 197.024 237.209 196.294 238.119 196.294H256.305C257.215 196.294 257.944 197.024 257.944 197.933V227.366C257.944 228.276 257.215 229.006 256.305 229.006H256.316Z"
                    fill="#367D79"
                  />
                  <path
                    d="M265.41 176.024H229.025C228.116 176.024 227.386 175.294 227.386 174.385V150.836C227.386 149.927 228.116 149.197 229.025 149.197H265.41C266.319 149.197 267.049 149.927 267.049 150.836V174.385C267.049 175.294 266.319 176.024 265.41 176.024ZM230.665 172.745H263.771V152.476H230.665V172.745Z"
                    fill="#367D79"
                  />
                  <path
                    d="M265.41 128.927H229.025C228.116 128.927 227.386 128.197 227.386 127.288V103.739C227.386 102.83 228.116 102.1 229.025 102.1H265.41C266.319 102.1 267.049 102.83 267.049 103.739V127.288C267.049 128.197 266.319 128.927 265.41 128.927ZM230.665 125.648H263.771V105.379H230.665V125.648Z"
                    fill="#367D79"
                  />
                  <path
                    d="M247.212 176.024C246.303 176.024 245.573 175.294 245.573 174.385V150.836C245.573 149.927 246.303 149.197 247.212 149.197C248.121 149.197 248.851 149.927 248.851 150.836V174.385C248.851 175.294 248.121 176.024 247.212 176.024Z"
                    fill="#367D79"
                  />
                  <path
                    d="M247.212 128.927C246.303 128.927 245.573 128.197 245.573 127.288V103.739C245.573 102.83 246.303 102.1 247.212 102.1C248.121 102.1 248.851 102.83 248.851 103.739V127.288C248.851 128.197 248.121 128.927 247.212 128.927Z"
                    fill="#367D79"
                  />
                  <path
                    d="M359.99 229.006H284.808C283.899 229.006 283.169 228.276 283.169 227.366V88.4333C283.169 87.928 283.405 87.4563 283.798 87.1419L321.395 57.7091C321.99 57.2486 322.82 57.2486 323.415 57.7091L361.012 87.1419C361.405 87.4563 361.641 87.928 361.641 88.4333V227.366C361.641 228.276 360.911 229.006 360.001 229.006H359.99ZM286.447 225.727H358.351V89.2306L322.394 61.0779L286.436 89.2306V225.727H286.447Z"
                    fill="#367D79"
                  />
                  <path
                    d="M331.498 229.006C330.589 229.006 329.859 228.276 329.859 227.366V199.573H314.94V227.366C314.94 228.276 314.21 229.006 313.301 229.006C312.391 229.006 311.661 228.276 311.661 227.366V197.933C311.661 197.024 312.391 196.294 313.301 196.294H331.487C332.396 196.294 333.126 197.024 333.126 197.933V227.366C333.126 228.276 332.396 229.006 331.487 229.006H331.498Z"
                    fill="#367D79"
                  />
                  <path
                    d="M340.591 176.024H304.207C303.298 176.024 302.568 175.294 302.568 174.385V150.836C302.568 149.927 303.298 149.197 304.207 149.197H340.591C341.501 149.197 342.231 149.927 342.231 150.836V174.385C342.231 175.294 341.501 176.024 340.591 176.024ZM305.846 172.745H338.952V152.476H305.846V172.745Z"
                    fill="#367D79"
                  />
                  <path
                    d="M340.591 128.927H304.207C303.298 128.927 302.568 128.197 302.568 127.288V103.739C302.568 102.83 303.298 102.1 304.207 102.1H340.591C341.501 102.1 342.231 102.83 342.231 103.739V127.288C342.231 128.197 341.501 128.927 340.591 128.927ZM305.846 125.648H338.952V105.379H305.846V125.648Z"
                    fill="#367D79"
                  />
                  <path
                    d="M322.394 176.024C321.484 176.024 320.755 175.294 320.755 174.385V150.836C320.755 149.927 321.484 149.197 322.394 149.197C323.303 149.197 324.033 149.927 324.033 150.836V174.385C324.033 175.294 323.303 176.024 322.394 176.024Z"
                    fill="#367D79"
                  />
                  <path
                    d="M322.394 128.927C321.484 128.927 320.755 128.197 320.755 127.288V103.739C320.755 102.83 321.484 102.1 322.394 102.1C323.303 102.1 324.033 102.83 324.033 103.739V127.288C324.033 128.197 323.303 128.927 322.394 128.927Z"
                    fill="#367D79"
                  />
                  <path
                    d="M435.172 229.006H359.99C359.081 229.006 358.351 228.276 358.351 227.366V88.4333C358.351 87.928 358.587 87.4563 358.98 87.1419L396.577 57.7091C397.172 57.2486 398.002 57.2486 398.597 57.7091L436.194 87.1419C436.587 87.4563 436.822 87.928 436.822 88.4333V227.366C436.822 228.276 436.093 229.006 435.183 229.006H435.172ZM361.629 225.727H433.533V89.2306L397.576 61.0779L361.618 89.2306V225.727H361.629Z"
                    fill="#367D79"
                  />
                  <path
                    d="M406.68 229.006C405.771 229.006 405.041 228.276 405.041 227.366V199.573H390.133V227.366C390.133 228.276 389.403 229.006 388.494 229.006C387.584 229.006 386.855 228.276 386.855 227.366V197.933C386.855 197.024 387.584 196.294 388.494 196.294H406.68C407.589 196.294 408.319 197.024 408.319 197.933V227.366C408.319 228.276 407.589 229.006 406.68 229.006Z"
                    fill="#367D79"
                  />
                  <path
                    d="M415.773 176.024H379.389C378.48 176.024 377.75 175.294 377.75 174.385V150.836C377.75 149.927 378.48 149.197 379.389 149.197H415.773C416.683 149.197 417.412 149.927 417.412 150.836V174.385C417.412 175.294 416.683 176.024 415.773 176.024ZM381.028 172.745H414.134V152.476H381.028V172.745Z"
                    fill="#367D79"
                  />
                  <path
                    d="M415.773 128.927H379.389C378.48 128.927 377.75 128.197 377.75 127.288V103.739C377.75 102.83 378.48 102.1 379.389 102.1H415.773C416.683 102.1 417.412 102.83 417.412 103.739V127.288C417.412 128.197 416.683 128.927 415.773 128.927ZM381.028 125.648H414.134V105.379H381.028V125.648Z"
                    fill="#367D79"
                  />
                  <path
                    d="M397.587 176.024C396.677 176.024 395.948 175.294 395.948 174.385V150.836C395.948 149.927 396.677 149.197 397.587 149.197C398.496 149.197 399.226 149.927 399.226 150.836V174.385C399.226 175.294 398.496 176.024 397.587 176.024Z"
                    fill="#367D79"
                  />
                  <path
                    d="M397.587 128.927C396.677 128.927 395.948 128.197 395.948 127.288V103.739C395.948 102.83 396.677 102.1 397.587 102.1C398.496 102.1 399.226 102.83 399.226 103.739V127.288C399.226 128.197 398.496 128.927 397.587 128.927Z"
                    fill="#367D79"
                  />
                  <path
                    d="M510.354 229.006H435.172C434.263 229.006 433.533 228.276 433.533 227.366V88.4333C433.533 87.928 433.769 87.4563 434.162 87.1419L471.758 57.7091C472.353 57.2486 473.184 57.2486 473.779 57.7091L511.375 87.1419C511.768 87.4563 512.004 87.928 512.004 88.4333V227.366C512.004 228.276 511.274 229.006 510.365 229.006H510.354ZM436.811 225.727H508.715V89.2306L472.757 61.0779L436.8 89.2306V225.727H436.811Z"
                    fill="#367D79"
                  />
                  <path
                    d="M481.862 229.006C480.953 229.006 480.223 228.276 480.223 227.366V199.573H465.303V227.366C465.303 228.276 464.573 229.006 463.664 229.006C462.755 229.006 462.025 228.276 462.025 227.366V197.933C462.025 197.024 462.755 196.294 463.664 196.294H481.851C482.76 196.294 483.49 197.024 483.49 197.933V227.366C483.49 228.276 482.76 229.006 481.851 229.006H481.862Z"
                    fill="#367D79"
                  />
                  <path
                    d="M490.955 176.024H454.571C453.662 176.024 452.932 175.294 452.932 174.385V150.836C452.932 149.927 453.662 149.197 454.571 149.197H490.955C491.864 149.197 492.594 149.927 492.594 150.836V174.385C492.594 175.294 491.864 176.024 490.955 176.024ZM456.21 172.745H489.316V152.476H456.21V172.745Z"
                    fill="#367D79"
                  />
                  <path
                    d="M490.955 128.927H454.571C453.662 128.927 452.932 128.197 452.932 127.288V103.739C452.932 102.83 453.662 102.1 454.571 102.1H490.955C491.864 102.1 492.594 102.83 492.594 103.739V127.288C492.594 128.197 491.864 128.927 490.955 128.927ZM456.21 125.648H489.316V105.379H456.21V125.648Z"
                    fill="#367D79"
                  />
                  <path
                    d="M472.769 176.024C471.859 176.024 471.13 175.294 471.13 174.385V150.836C471.13 149.927 471.859 149.197 472.769 149.197C473.678 149.197 474.408 149.927 474.408 150.836V174.385C474.408 175.294 473.678 176.024 472.769 176.024Z"
                    fill="#367D79"
                  />
                  <path
                    d="M472.769 128.927C471.859 128.927 471.13 128.197 471.13 127.288V103.739C471.13 102.83 471.859 102.1 472.769 102.1C473.678 102.1 474.408 102.83 474.408 103.739V127.288C474.408 128.197 473.678 128.927 472.769 128.927Z"
                    fill="#367D79"
                  />
                  <path
                    d="M153.013 338.012L43.8495 337.843C42.9402 337.843 42.2104 337.113 42.2104 336.204V151.971C42.2104 151.061 42.9402 150.331 43.8495 150.331C44.7588 150.331 45.4885 151.061 45.4885 151.971V334.576L153.013 334.744C153.922 334.744 154.652 335.485 154.652 336.384C154.652 337.282 153.922 338.023 153.013 338.023V338.012Z"
                    fill="#367D79"
                  />
                  <path
                    d="M135.657 287.153C134.748 287.153 134.018 286.423 134.018 285.513V151.959C134.018 151.05 134.748 150.32 135.657 150.32C136.567 150.32 137.296 151.05 137.296 151.959V285.513C137.296 286.423 136.567 287.153 135.657 287.153Z"
                    fill="#367D79"
                  />
                  <path
                    d="M80.3908 201.033H55.9065C54.9972 201.033 54.2675 200.303 54.2675 199.393V175.62C54.2675 174.711 54.9972 173.981 55.9065 173.981H80.3908C81.3001 173.981 82.0298 174.711 82.0298 175.62V199.393C82.0298 200.303 81.3001 201.033 80.3908 201.033ZM57.5455 197.754H78.7518V177.26H57.5455V197.754Z"
                    fill="#367D79"
                  />
                  <path
                    d="M123.32 201.156H98.8354C97.9261 201.156 97.1964 200.426 97.1964 199.517V175.744C97.1964 174.834 97.9261 174.104 98.8354 174.104H123.32C124.229 174.104 124.959 174.834 124.959 175.744V199.517C124.959 200.426 124.229 201.156 123.32 201.156ZM100.474 197.877H121.681V177.383H100.474V197.877Z"
                    fill="#367D79"
                  />
                  <path
                    d="M172.064 296.417H147.624C146.715 296.417 145.985 295.687 145.985 294.778V205.345C145.985 204.435 146.715 203.705 147.624 203.705H172.064C172.973 203.705 173.703 204.435 173.703 205.345V294.778C173.703 295.687 172.973 296.417 172.064 296.417ZM149.263 293.138H170.425V206.985H149.263V293.138Z"
                    fill="#367D79"
                  />
                  <path
                    d="M80.3908 236.743H55.9065C54.9972 236.743 54.2675 236.013 54.2675 235.104V211.33C54.2675 210.421 54.9972 209.691 55.9065 209.691H80.3908C81.3001 209.691 82.0298 210.421 82.0298 211.33V235.104C82.0298 236.013 81.3001 236.743 80.3908 236.743ZM57.5455 233.464H78.7518V212.97H57.5455V233.464Z"
                    fill="#367D79"
                  />
                  <path
                    d="M123.32 236.721H98.8354C97.9261 236.721 97.1964 235.991 97.1964 235.081V211.308C97.1964 210.398 97.9261 209.668 98.8354 209.668H123.32C124.229 209.668 124.959 210.398 124.959 211.308V235.081C124.959 235.991 124.229 236.721 123.32 236.721ZM100.474 233.442H121.681V212.947H100.474V233.442Z"
                    fill="#367D79"
                  />
                  <path
                    d="M80.402 272.352H55.9177C55.0084 272.352 54.2787 271.622 54.2787 270.713V246.94C54.2787 246.03 55.0084 245.3 55.9177 245.3H80.402C81.3114 245.3 82.0411 246.03 82.0411 246.94V270.713C82.0411 271.622 81.3114 272.352 80.402 272.352ZM57.5567 269.073H78.763V248.579H57.5567V269.073Z"
                    fill="#367D79"
                  />
                  <path
                    d="M111.296 324.02C110.387 324.02 109.657 323.29 109.657 322.38V296.148H70.0066V322.38C70.0066 323.29 69.2769 324.02 68.3675 324.02C67.4582 324.02 66.7285 323.29 66.7285 322.38V294.508C66.7285 293.599 67.4582 292.869 68.3675 292.869H111.296C112.206 292.869 112.936 293.599 112.936 294.508V322.38C112.936 323.29 112.206 324.02 111.296 324.02Z"
                    fill="#367D79"
                  />
                  <path
                    d="M123.32 272.397H98.8354C97.9261 272.397 97.1964 271.667 97.1964 270.758V246.984C97.1964 246.075 97.9261 245.345 98.8354 245.345H123.32C124.229 245.345 124.959 246.075 124.959 246.984V270.758C124.959 271.667 124.229 272.397 123.32 272.397ZM100.474 269.118H121.681V248.624H100.474V269.118Z"
                    fill="#367D79"
                  />
                  <path
                    d="M141.54 153.599H37.5179C36.6086 153.599 35.8789 152.869 35.8789 151.959V140.056C35.8789 139.146 36.6086 138.416 37.5179 138.416H52.9203C53.8296 138.416 54.5593 139.146 54.5593 140.056C54.5593 140.966 53.8296 141.695 52.9203 141.695H39.157V150.331H139.912V141.695H59.0273C58.118 141.695 57.3883 140.966 57.3883 140.056C57.3883 139.146 58.118 138.416 59.0273 138.416H141.54C142.449 138.416 143.179 139.146 143.179 140.056V151.959C143.179 152.869 142.449 153.599 141.54 153.599Z"
                    fill="#367D79"
                  />
                  <path
                    d="M190.464 195.227H135.624C134.714 195.227 133.985 194.497 133.985 193.588C133.985 192.678 134.714 191.948 135.624 191.948H188.825V183.312H135.624C134.714 183.312 133.985 182.583 133.985 181.673C133.985 180.763 134.714 180.033 135.624 180.033H190.464C191.373 180.033 192.103 180.763 192.103 181.673V193.576C192.103 194.486 191.373 195.216 190.464 195.216V195.227Z"
                    fill="#367D79"
                  />
                  <path
                    d="M89.7534 324.02C88.8441 324.02 88.1144 323.29 88.1144 322.38V294.778C88.1144 293.868 88.8441 293.138 89.7534 293.138C90.6627 293.138 91.3924 293.868 91.3924 294.778V322.38C91.3924 323.29 90.6627 324.02 89.7534 324.02Z"
                    fill="#367D79"
                  />
                  <path
                    d="M77.225 168.209C76.3156 168.209 75.5859 167.479 75.5859 166.569V160.629C75.5859 159.719 76.3156 158.989 77.225 158.989C78.1343 158.989 78.864 159.719 78.864 160.629V166.569C78.864 167.479 78.1343 168.209 77.225 168.209Z"
                    fill="#367D79"
                  />
                  <path
                    d="M89.5289 168.209C88.6196 168.209 87.8899 167.479 87.8899 166.569V160.629C87.8899 159.719 88.6196 158.989 89.5289 158.989C90.4382 158.989 91.1679 159.719 91.1679 160.629V166.569C91.1679 167.479 90.4382 168.209 89.5289 168.209Z"
                    fill="#367D79"
                  />
                  <path
                    d="M101.878 168.209C100.968 168.209 100.239 167.479 100.239 166.569V160.629C100.239 159.719 100.968 158.989 101.878 158.989C102.787 158.989 103.517 159.719 103.517 160.629V166.569C103.517 167.479 102.787 168.209 101.878 168.209Z"
                    fill="#367D79"
                  />
                  <path
                    d="M184.615 325.918C183.705 325.918 182.976 325.188 182.976 324.278V193.588C182.976 192.678 183.705 191.948 184.615 191.948C185.524 191.948 186.254 192.678 186.254 193.588V324.278C186.254 325.188 185.524 325.918 184.615 325.918Z"
                    fill="#367D79"
                  />
                  <path
                    d="M172.008 236.709H147.568C146.659 236.709 145.929 235.979 145.929 235.07C145.929 234.16 146.659 233.43 147.568 233.43H172.008C172.917 233.43 173.647 234.16 173.647 235.07C173.647 235.979 172.917 236.709 172.008 236.709Z"
                    fill="#367D79"
                  />
                  <path
                    d="M172.468 266.457H147.669C146.76 266.457 146.03 265.727 146.03 264.817C146.03 263.907 146.76 263.178 147.669 263.178H172.468C173.377 263.178 174.107 263.907 174.107 264.817C174.107 265.727 173.377 266.457 172.468 266.457Z"
                    fill="#367D79"
                  />
                  <path
                    d="M201.937 323.649C201.027 323.649 200.298 322.919 200.298 322.01V253.868C200.298 252.959 201.027 252.229 201.937 252.229C202.846 252.229 203.576 252.959 203.576 253.868V322.01C203.576 322.919 202.846 323.649 201.937 323.649Z"
                    fill="#367D79"
                  />
                  <path
                    d="M226.937 337.989H186.77C186.332 337.989 185.917 337.81 185.603 337.506C185.3 337.192 185.12 336.777 185.131 336.339L185.154 333.206C185.154 327.478 190.116 322.638 195.998 322.638H201.982C203.329 320.078 207.539 313.745 216.239 313.745C216.385 313.745 216.531 313.745 216.677 313.745C224.412 313.936 230.855 320.089 231.361 327.748C231.495 329.792 231.215 331.791 230.53 333.7C230.485 333.812 230.44 333.924 230.373 334.025L228.318 337.226C228.015 337.697 227.499 337.978 226.937 337.978V337.989ZM188.42 334.71H226.051L227.521 332.431C228.015 331.005 228.206 329.5 228.105 327.973C227.712 321.987 222.66 317.17 216.609 317.024C207.943 316.81 204.676 324.547 204.541 324.873C204.294 325.491 203.688 325.895 203.026 325.895H196.009C191.968 325.906 188.432 329.32 188.432 333.206V334.699L188.42 334.71Z"
                    fill="#367D79"
                  />
                  <path
                    d="M45.7692 325.918C44.8598 325.918 44.1301 325.188 44.1301 324.278C44.1301 323.368 44.8598 322.638 45.7692 322.638L203.014 322.616C203.924 322.616 204.653 323.346 204.653 324.256C204.653 325.165 203.924 325.895 203.014 325.895L45.7692 325.918Z"
                    fill="#367D79"
                  />
                  <path
                    d="M201.982 272.42C201.566 272.42 201.162 272.262 200.837 271.959L192.192 263.559C191.541 262.931 191.53 261.897 192.159 261.246C192.787 260.595 193.82 260.584 194.471 261.212L201.982 268.5L209.391 261.302C210.042 260.673 211.075 260.685 211.704 261.336C212.332 261.987 212.321 263.02 211.67 263.649L203.116 271.948C202.801 272.251 202.386 272.408 201.97 272.408L201.982 272.42Z"
                    fill="#367D79"
                  />
                  <path
                    d="M201.982 283.93C201.566 283.93 201.162 283.773 200.837 283.47L192.192 275.07C191.541 274.441 191.53 273.408 192.159 272.756C192.787 272.105 193.82 272.094 194.471 272.723L201.982 280.011L209.391 272.813C210.042 272.184 211.075 272.195 211.704 272.846C212.332 273.498 212.321 274.531 211.67 275.16L203.116 283.458C202.801 283.761 202.386 283.919 201.97 283.919L201.982 283.93Z"
                    fill="#367D79"
                  />
                  <path
                    d="M201.723 291.982C198.131 291.982 194.55 291.139 191.373 289.556C188.667 288.197 186.501 286.468 184.547 284.098C183.975 283.402 184.076 282.369 184.772 281.796C185.468 281.224 186.501 281.325 187.073 282.021C188.712 284.02 190.542 285.48 192.832 286.625C195.594 288.006 198.58 288.703 201.712 288.703C212.411 288.703 221.111 280.28 221.111 269.915C221.111 263.357 216.71 257.518 212.4 252.667C210.603 250.645 204.44 245.053 201.926 242.785C199.175 245.289 191.777 252.229 186.961 258.54C186.411 259.258 185.378 259.393 184.671 258.843C183.952 258.293 183.818 257.271 184.357 256.552C190.542 248.444 200.421 239.719 200.837 239.348C201.454 238.798 202.397 238.809 203.014 239.348C203.396 239.696 212.467 247.793 214.858 250.488C219.573 255.8 224.389 262.257 224.389 269.915C224.389 282.077 214.218 291.97 201.723 291.97V291.982Z"
                    fill="#367D79"
                  />
                  <path
                    d="M135.657 325.974C134.748 325.974 134.018 325.244 134.018 324.334V291.454C134.018 290.544 134.748 289.814 135.657 289.814C136.567 289.814 137.296 290.544 137.296 291.454V324.334C137.296 325.244 136.567 325.974 135.657 325.974Z"
                    fill="#367D79"
                  />
                  <path
                    d="M187.545 337.888H166.305C165.395 337.888 164.666 337.158 164.666 336.249C164.666 335.339 165.395 334.609 166.305 334.609H187.545C188.454 334.609 189.184 335.339 189.184 336.249C189.184 337.158 188.454 337.888 187.545 337.888Z"
                    fill="#367D79"
                  />
                  <path
                    d="M281.811 276.586C281.699 276.586 281.586 276.575 281.485 276.552C281.373 276.53 281.272 276.496 281.16 276.451C281.059 276.406 280.969 276.361 280.879 276.294C280.778 276.238 280.7 276.159 280.621 276.092C280.542 276.013 280.475 275.923 280.408 275.833C280.352 275.744 280.295 275.642 280.251 275.541C280.217 275.44 280.183 275.339 280.161 275.227C280.138 275.115 280.127 275.014 280.127 274.901C280.127 274.452 280.307 274.014 280.621 273.711C280.7 273.632 280.778 273.565 280.879 273.498C280.969 273.441 281.059 273.385 281.16 273.34C281.272 273.307 281.373 273.273 281.485 273.251C281.699 273.206 281.923 273.206 282.137 273.251C282.249 273.273 282.35 273.307 282.451 273.34C282.552 273.385 282.653 273.441 282.743 273.498C282.833 273.565 282.922 273.632 283.001 273.711C283.068 273.79 283.147 273.868 283.203 273.958C283.27 274.059 283.315 274.149 283.36 274.25C283.405 274.351 283.439 274.463 283.461 274.564C283.484 274.677 283.495 274.789 283.495 274.901C283.495 275.339 283.315 275.777 283.001 276.092C282.687 276.395 282.249 276.586 281.811 276.586Z"
                    fill="#367D79"
                  />
                  <path
                    d="M370.296 306.445C369.982 306.445 369.667 306.356 369.387 306.176L314.064 270.466L258.741 306.176C257.978 306.67 256.956 306.468 256.44 305.715L249.614 295.732C249.356 295.362 249.266 294.89 249.356 294.452C249.446 294.003 249.715 293.621 250.097 293.374L270.394 280.269C271.169 279.764 272.213 279.988 272.718 280.774C273.223 281.56 272.998 282.594 272.213 283.099L253.364 295.261L258.292 302.459L313.155 267.052C313.705 266.692 314.423 266.692 314.973 267.052L369.836 302.459L374.764 295.261L314.064 256.08L293.228 269.522C292.453 270.028 291.409 269.803 290.904 269.017C290.399 268.231 290.624 267.198 291.409 266.692L313.155 252.655C313.705 252.296 314.423 252.296 314.973 252.655L378.042 293.363C378.424 293.61 378.693 294.003 378.783 294.441C378.873 294.89 378.783 295.35 378.525 295.721L371.699 305.704C371.374 306.176 370.846 306.434 370.307 306.434L370.296 306.445Z"
                    fill="#367D79"
                  />
                  <path
                    d="M372.519 379H255.609C254.677 379 253.925 378.248 253.925 377.316V365.334C253.925 364.401 254.677 363.649 255.609 363.649H285.707C286.638 363.649 287.39 364.401 287.39 365.334C287.39 366.266 286.638 367.018 285.707 367.018H257.293V375.631H372.53C373.462 375.631 374.214 376.383 374.214 377.316C374.214 378.248 373.462 379 372.53 379H372.519Z"
                    fill="#367D79"
                  />
                  <path
                    d="M355.578 367.018H316.612C315.68 367.018 314.928 366.266 314.928 365.334C314.928 364.401 315.68 363.649 316.612 363.649H355.578C356.51 363.649 357.262 364.401 357.262 365.334C357.262 366.266 356.51 367.018 355.578 367.018Z"
                    fill="#367D79"
                  />
                  <path
                    d="M367.916 364.3C366.984 364.3 366.232 363.548 366.232 362.616V304.761C366.232 303.829 366.984 303.076 367.916 303.076C368.848 303.076 369.6 303.829 369.6 304.761V362.616C369.6 363.548 368.848 364.3 367.916 364.3Z"
                    fill="#367D79"
                  />
                  <path
                    d="M260.223 367.018C259.291 367.018 258.539 366.266 258.539 365.334V304.761C258.539 303.829 259.291 303.076 260.223 303.076C261.155 303.076 261.907 303.829 261.907 304.761V365.334C261.907 366.266 261.155 367.018 260.223 367.018Z"
                    fill="#367D79"
                  />
                  <path
                    d="M355.578 367.018H319.677C318.745 367.018 317.993 366.266 317.993 365.334V309.781C317.993 308.848 318.745 308.096 319.677 308.096H355.578C356.51 308.096 357.262 308.848 357.262 309.781V365.334C357.262 366.266 356.51 367.018 355.578 367.018ZM321.361 363.649H353.894V311.465H321.361V363.649Z"
                    fill="#367D79"
                  />
                  <path
                    d="M307.339 345.232H272.561C271.629 345.232 270.877 344.48 270.877 343.548V309.781C270.877 308.848 271.629 308.096 272.561 308.096H307.339C308.271 308.096 309.023 308.848 309.023 309.781V343.548C309.023 344.48 308.271 345.232 307.339 345.232ZM274.245 341.864H305.655V311.465H274.245V341.864Z"
                    fill="#367D79"
                  />
                  <path
                    d="M289.95 345.232C289.018 345.232 288.266 344.48 288.266 343.548V309.781C288.266 308.848 289.018 308.096 289.95 308.096C290.882 308.096 291.634 308.848 291.634 309.781V343.548C291.634 344.48 290.882 345.232 289.95 345.232Z"
                    fill="#367D79"
                  />
                  <path
                    d="M332.116 339.247C332.003 339.247 331.891 339.247 331.779 339.213C331.678 339.191 331.566 339.168 331.464 339.124C331.363 339.079 331.274 339.023 331.184 338.966C331.083 338.91 331.004 338.832 330.926 338.753C330.847 338.686 330.78 338.596 330.712 338.506C330.656 338.416 330.6 338.315 330.555 338.214C330.521 338.113 330.488 338.001 330.465 337.9C330.443 337.787 330.432 337.675 330.432 337.563C330.432 337.462 330.432 337.349 330.465 337.237C330.488 337.136 330.521 337.024 330.555 336.923C330.6 336.822 330.656 336.72 330.712 336.631C330.78 336.541 330.847 336.451 330.926 336.384C331.004 336.305 331.083 336.226 331.184 336.17C331.274 336.103 331.363 336.058 331.464 336.013C331.566 335.968 331.678 335.946 331.779 335.923C332.003 335.878 332.228 335.878 332.441 335.923C332.553 335.946 332.654 335.968 332.755 336.013C332.857 336.058 332.958 336.103 333.047 336.17C333.137 336.226 333.227 336.305 333.294 336.384C333.373 336.451 333.451 336.541 333.508 336.631C333.575 336.72 333.62 336.822 333.665 336.923C333.71 337.024 333.743 337.136 333.766 337.237C333.788 337.349 333.799 337.462 333.799 337.563C333.799 338.012 333.62 338.439 333.294 338.753C333.227 338.832 333.137 338.91 333.047 338.966C332.958 339.023 332.857 339.079 332.755 339.124C332.654 339.168 332.553 339.191 332.441 339.213C332.329 339.236 332.228 339.247 332.116 339.247Z"
                    fill="#367D79"
                  />
                  <path
                    d="M364.851 288.534C363.919 288.534 363.167 287.782 363.167 286.85V262.931C363.167 261.998 363.919 261.246 364.851 261.246C365.783 261.246 366.535 261.998 366.535 262.931V286.85C366.535 287.782 365.783 288.534 364.851 288.534Z"
                    fill="#367D79"
                  />
                  <path
                    d="M347.9 277.158C346.968 277.158 346.216 276.406 346.216 275.474V262.931C346.216 261.998 346.968 261.246 347.9 261.246C348.831 261.246 349.584 261.998 349.584 262.931V275.474C349.584 276.406 348.831 277.158 347.9 277.158Z"
                    fill="#367D79"
                  />
                  <path
                    d="M368.017 264.615H344.734C343.802 264.615 343.05 263.863 343.05 262.931C343.05 261.998 343.802 261.246 344.734 261.246H368.017C368.949 261.246 369.701 261.998 369.701 262.931C369.701 263.863 368.949 264.615 368.017 264.615Z"
                    fill="#367D79"
                  />
                  <path
                    d="M314.423 379H286.897C286.38 379 285.897 378.764 285.583 378.371C283.809 376.148 282.866 373.497 282.866 370.679C282.866 363.773 288.625 358.158 295.709 358.158C299.245 358.158 302.636 359.606 305.038 362.077C306.475 361.336 308.058 360.943 309.719 360.943C315.22 360.943 319.699 365.311 319.699 370.679C319.699 373.891 318.072 376.9 315.344 378.719C315.063 378.899 314.738 379 314.412 379H314.423ZM287.75 375.631H313.884C315.434 374.418 316.343 372.599 316.343 370.668C316.343 367.153 313.379 364.3 309.731 364.3C308.26 364.3 306.879 364.75 305.712 365.614C304.971 366.165 303.927 366.007 303.377 365.277C301.592 362.919 298.729 361.515 295.72 361.515C290.489 361.515 286.234 365.625 286.234 370.668C286.234 372.453 286.751 374.149 287.75 375.631Z"
                    fill="#367D79"
                  />
                  <path
                    d="M386.641 379H359.115C358.778 379 358.452 378.899 358.183 378.719C355.455 376.9 353.827 373.891 353.827 370.679C353.827 365.311 358.306 360.943 363.807 360.943C365.469 360.943 367.051 361.336 368.488 362.077C370.902 359.595 374.281 358.158 377.817 358.158C384.901 358.158 390.66 363.773 390.66 370.679C390.66 373.497 389.728 376.159 387.943 378.371C387.629 378.764 387.146 379 386.63 379H386.641ZM359.653 375.631H385.788C386.776 374.149 387.304 372.453 387.304 370.668C387.304 365.614 383.049 361.515 377.817 361.515C374.809 361.515 371.946 362.919 370.161 365.277C369.6 366.007 368.556 366.165 367.826 365.614C366.67 364.761 365.278 364.3 363.807 364.3C360.159 364.3 357.195 367.153 357.195 370.668C357.195 372.599 358.104 374.418 359.653 375.631Z"
                    fill="#367D79"
                  />
                  <path
                    d="M73.5765 107.681H40.9419C40.0101 107.681 39.258 106.929 39.258 105.996C39.258 105.064 40.0101 104.312 40.9419 104.312H73.5765C79.7958 104.312 84.8476 99.4271 84.8476 93.4193C84.8476 88.3659 81.1541 83.8965 76.0574 82.796C75.5972 82.695 75.2043 82.4142 74.9685 82.01C74.7327 81.6057 74.6654 81.1228 74.8001 80.6849C75.2604 79.1015 75.4961 77.462 75.4961 75.8C75.4961 65.8393 67.1326 57.7427 56.8382 57.7427C47.251 57.7427 39.2804 64.6939 38.2813 73.9134C38.2139 74.5423 37.7986 75.07 37.2148 75.3059C36.6311 75.5305 35.9575 75.4182 35.486 74.9914C33.0162 72.8017 29.4575 72.2626 26.4152 73.6102C23.1484 75.0588 21.2287 78.3379 21.6329 81.7741C21.7002 82.3693 21.442 82.9645 20.9593 83.3238C20.4766 83.6832 19.8367 83.7506 19.2866 83.5147C17.816 82.8634 16.2443 82.5378 14.6277 82.5378C8.40841 82.5378 3.35663 87.4226 3.35663 93.4305C3.35663 99.4383 8.40841 104.323 14.6277 104.323H27.1449C28.0767 104.323 28.8289 105.076 28.8289 106.008C28.8289 106.94 28.0767 107.692 27.1449 107.692H14.6277C6.55609 107.692 -0.0112305 101.291 -0.0112305 93.4305C-0.0112305 85.5697 6.55609 79.1689 14.6277 79.1689C15.8738 79.1689 17.0975 79.3149 18.2762 79.6181C18.7141 75.6877 21.24 72.2065 25.0569 70.522C28.4247 69.0397 32.2416 69.2082 35.3962 70.8589C37.6975 61.2913 46.4652 54.3738 56.827 54.3738C68.9625 54.3738 78.8415 63.9864 78.8415 75.8C78.8415 77.2037 78.7068 78.6074 78.4262 79.9774C84.1852 81.9538 88.2042 87.3665 88.2042 93.4305C88.2042 101.291 81.6369 107.692 73.5652 107.692L73.5765 107.681Z"
                    fill="#367D79"
                  />
                  <path
                    d="M56.8944 64.4356H56.8382C55.9065 64.4356 55.1543 63.6832 55.1543 62.7512C55.1543 61.8191 55.9065 61.0667 56.8382 61.0667C57.77 61.0667 58.5446 61.8191 58.5446 62.7512C58.5446 63.6832 57.8149 64.4356 56.8944 64.4356Z"
                    fill="#367D79"
                  />
                  <path
                    d="M70.276 77.4844C69.3442 77.4844 68.592 76.732 68.592 75.8C68.592 71.7686 66.3468 67.9954 62.7432 65.9741C61.9349 65.5136 61.643 64.4917 62.1033 63.6832C62.5636 62.8747 63.5852 62.5827 64.3934 63.0431C69.0635 65.6709 71.9599 70.5558 71.9599 75.8112C71.9599 76.7433 71.2077 77.4957 70.276 77.4957V77.4844Z"
                    fill="#367D79"
                  />
                  <path
                    d="M34.0603 107.692C33.6112 107.692 33.1734 107.512 32.8703 107.198C32.7917 107.119 32.7131 107.041 32.657 106.94C32.5896 106.85 32.5447 106.76 32.4998 106.648C32.4549 106.547 32.4212 106.446 32.3988 106.333C32.3988 106.232 32.3763 106.12 32.3763 106.008C32.3763 105.895 32.3876 105.783 32.3988 105.682C32.4212 105.57 32.4549 105.469 32.4998 105.368C32.5447 105.267 32.5896 105.165 32.657 105.076C32.7131 104.986 32.7917 104.896 32.8703 104.817C33.4877 104.2 34.6216 104.188 35.239 104.817C35.3176 104.896 35.385 104.986 35.4523 105.076C35.5085 105.165 35.5646 105.267 35.6095 105.368C35.6544 105.469 35.6768 105.57 35.6993 105.682C35.7217 105.783 35.733 105.895 35.733 106.008C35.733 106.12 35.733 106.232 35.6993 106.333C35.6768 106.446 35.6544 106.547 35.6095 106.648C35.5646 106.76 35.5085 106.85 35.4523 106.94C35.385 107.041 35.3176 107.119 35.239 107.198C34.9247 107.512 34.4981 107.692 34.0603 107.692Z"
                    fill="#367D79"
                  />
                  <path
                    d="M591.935 128.467H564.79C563.858 128.467 563.106 127.715 563.106 126.783C563.106 125.85 563.858 125.098 564.79 125.098H591.935C596.953 125.098 601.028 121.156 601.028 116.316C601.028 112.24 598.042 108.647 593.933 107.748C593.473 107.647 593.08 107.366 592.844 106.962C592.608 106.558 592.541 106.075 592.676 105.637C593.046 104.346 593.237 103.009 593.237 101.651C593.237 93.5203 586.4 86.9061 578.003 86.9061C570.178 86.9061 563.667 92.5771 562.848 100.101C562.78 100.73 562.365 101.258 561.781 101.493C561.197 101.718 560.535 101.606 560.063 101.179C558.099 99.4384 555.259 99.0004 552.823 100.078C550.218 101.224 548.691 103.84 549.006 106.58C549.073 107.176 548.815 107.771 548.332 108.13C547.849 108.489 547.209 108.557 546.659 108.321C545.469 107.804 544.212 107.535 542.899 107.535C537.88 107.535 533.805 111.477 533.805 116.316C533.805 121.156 537.88 125.098 542.899 125.098H553.305C554.237 125.098 554.989 125.85 554.989 126.783C554.989 127.715 554.237 128.467 553.305 128.467H542.899C536.028 128.467 530.438 123.021 530.438 116.316C530.438 109.612 536.028 104.166 542.899 104.166C543.853 104.166 544.785 104.267 545.694 104.469C546.165 101.235 548.298 98.394 551.453 96.9903C554.215 95.7663 557.347 95.8561 559.985 97.1251C562.05 89.2194 569.381 83.5372 578.003 83.5372C588.264 83.5372 596.605 91.6562 596.605 101.639C596.605 102.751 596.504 103.852 596.302 104.93C601.084 106.681 604.396 111.229 604.396 116.305C604.396 122.998 598.805 128.456 591.935 128.456V128.467Z"
                    fill="#367D79"
                  />
                  <path
                    d="M578.048 92.4872H578.003C577.071 92.4872 576.319 91.7348 576.319 90.8028C576.319 89.8707 577.071 89.1183 578.003 89.1183C578.935 89.1183 579.709 89.8707 579.709 90.8028C579.709 91.7348 578.979 92.4872 578.048 92.4872Z"
                    fill="#367D79"
                  />
                  <path
                    d="M589.184 103.335C588.252 103.335 587.5 102.583 587.5 101.651C587.5 98.394 585.693 95.362 582.774 93.7225C581.966 93.2621 581.674 92.2402 582.134 91.4316C582.583 90.6231 583.616 90.3311 584.424 90.7915C588.398 93.0262 590.868 97.1924 590.868 101.651C590.868 102.583 590.116 103.335 589.184 103.335Z"
                    fill="#367D79"
                  />
                  <path
                    d="M559.053 128.467C558.941 128.467 558.829 128.456 558.716 128.433C558.615 128.411 558.503 128.377 558.402 128.343C558.301 128.298 558.211 128.242 558.121 128.186C558.02 128.119 557.942 128.051 557.863 127.973C557.549 127.658 557.369 127.232 557.369 126.782C557.369 126.67 557.369 126.569 557.403 126.457C557.425 126.345 557.459 126.243 557.493 126.142C557.538 126.041 557.594 125.94 557.65 125.85C557.717 125.761 557.785 125.671 557.863 125.592C557.942 125.514 558.02 125.446 558.121 125.39C558.211 125.323 558.301 125.278 558.402 125.233C558.503 125.188 558.615 125.154 558.716 125.132C558.941 125.098 559.165 125.098 559.379 125.132C559.48 125.154 559.592 125.188 559.693 125.233C559.794 125.278 559.895 125.323 559.985 125.39C560.075 125.446 560.164 125.514 560.243 125.592C560.31 125.671 560.389 125.761 560.445 125.85C560.512 125.94 560.557 126.041 560.602 126.142C560.647 126.243 560.681 126.345 560.703 126.457C560.703 126.569 560.737 126.67 560.737 126.782C560.737 127.232 560.546 127.658 560.243 127.973C560.164 128.051 560.075 128.119 559.985 128.186C559.895 128.242 559.794 128.298 559.693 128.343C559.592 128.377 559.48 128.411 559.379 128.433C559.266 128.456 559.154 128.467 559.053 128.467Z"
                    fill="#367D79"
                  />
                  <path
                    d="M260.638 33.4418H253.263C252.331 33.4418 251.579 32.6894 251.579 31.7574C251.579 30.8253 252.331 30.0729 253.263 30.0729H260.638C263.928 30.0729 266.6 27.5013 266.6 24.3346C266.6 21.1678 263.928 18.5963 260.638 18.5963C259.785 18.5963 258.955 18.7647 258.169 19.1128C257.619 19.3486 256.979 19.2813 256.496 18.9219C256.013 18.5626 255.755 17.9786 255.822 17.3722C256.025 15.6429 255.048 13.9921 253.409 13.2622C251.86 12.5772 250.052 12.8467 248.795 13.9584C248.323 14.3739 247.661 14.4974 247.077 14.2728C246.493 14.0483 246.078 13.5092 246.011 12.8804C245.461 7.79335 241.049 3.96404 235.75 3.96404C230.07 3.96404 225.433 8.43344 225.433 13.9247C225.433 14.8343 225.557 15.7439 225.815 16.6086C225.95 17.0578 225.882 17.5407 225.646 17.9337C225.411 18.338 225.018 18.6187 224.558 18.7198C221.863 19.3037 219.91 21.6507 219.91 24.3121C219.91 27.4789 222.582 30.0505 225.871 30.0505H245.124C246.056 30.0505 246.808 30.8029 246.808 31.7349C246.808 32.667 246.056 33.4194 245.124 33.4194H225.871C220.729 33.4194 216.553 29.3318 216.553 24.3234C216.553 20.6513 218.866 17.361 222.234 15.9461C222.133 15.2835 222.077 14.6097 222.077 13.9247C222.077 6.58055 228.206 0.595154 235.75 0.595154C241.891 0.595154 247.133 4.5143 248.84 10.0505C250.715 9.32058 252.859 9.33181 254.767 10.1853C257.001 11.1735 258.55 13.1162 259.044 15.3509C259.561 15.2611 260.1 15.2161 260.638 15.2161C265.78 15.2161 269.956 19.3037 269.956 24.3121C269.956 29.3205 265.769 33.4081 260.638 33.4081V33.4418Z"
                    fill="#367D79"
                  />
                  <path
                    d="M249.188 33.4531C249.076 33.4531 248.975 33.4531 248.862 33.4194C248.75 33.3969 248.649 33.3632 248.548 33.3183C248.447 33.2734 248.346 33.2285 248.256 33.1611C248.166 33.105 248.076 33.0376 247.998 32.959C247.919 32.8804 247.852 32.7905 247.796 32.7007C247.728 32.6109 247.684 32.5098 247.639 32.4087C247.594 32.3077 247.56 32.2066 247.538 32.0943C247.515 31.982 247.504 31.8809 247.504 31.7686C247.504 31.3194 247.684 30.8927 247.998 30.5783C248.076 30.4997 248.166 30.4323 248.256 30.3649C248.346 30.3088 248.447 30.2526 248.548 30.2077C248.649 30.174 248.75 30.1403 248.862 30.1179C249.412 30.0056 249.985 30.1853 250.378 30.5783C250.692 30.8927 250.872 31.3194 250.872 31.7686C250.872 31.8809 250.872 31.982 250.838 32.0943C250.816 32.2066 250.782 32.3077 250.748 32.4087C250.703 32.5098 250.647 32.6109 250.591 32.7007C250.524 32.7905 250.456 32.8804 250.378 32.959C250.063 33.2734 249.637 33.4531 249.188 33.4531Z"
                    fill="#367D79"
                  />
                  <path
                    d="M431.569 292.633H428.201V340.842H431.569V292.633Z"
                    fill="#367D79"
                  />
                  <path
                    d="M438.956 301.652L428.704 311.611L431.05 314.028L441.302 304.069L438.956 301.652Z"
                    fill="#367D79"
                  />
                  <path
                    d="M423.304 295.247L420.958 297.665L428.692 305.172L431.038 302.755L423.304 295.247Z"
                    fill="#367D79"
                  />
                  <path
                    d="M457.557 259.55H454.189V339.595H457.557V259.55Z"
                    fill="#367D79"
                  />
                  <path
                    d="M461.811 265.05L454.74 271.919L457.086 274.336L464.157 267.467L461.811 265.05Z"
                    fill="#367D79"
                  />
                  <path
                    d="M448.206 273.076L445.86 275.493L454.302 283.69L456.647 281.273L448.206 273.076Z"
                    fill="#367D79"
                  />
                  <path
                    d="M490.641 327.085C487.037 327.085 483.568 326.108 480.548 324.256C477.753 326.12 474.497 327.085 471.074 327.085C461.756 327.085 454.178 319.708 454.178 310.634C454.178 303.705 458.579 297.63 465.101 295.238C464.405 293.363 464.046 291.386 464.046 289.354C464.046 279.786 472.039 272.004 481.862 272.004C491.685 272.004 499.678 279.786 499.678 289.354C499.678 290.365 499.588 291.364 499.408 292.352C505.616 295.519 509.546 301.774 509.546 308.669C509.546 318.82 501.059 327.085 490.63 327.085H490.641ZM480.47 320.123L481.48 320.842C484.152 322.728 487.318 323.728 490.641 323.728C499.218 323.728 506.189 316.979 506.189 308.68C506.189 302.683 502.518 297.26 496.826 294.868L495.468 294.295L495.85 292.88C496.153 291.735 496.31 290.555 496.31 289.365C496.31 281.662 489.832 275.384 481.862 275.384C473.891 275.384 467.414 281.662 467.414 289.365C467.414 291.532 467.919 293.621 468.907 295.553L469.884 297.45L467.807 297.945C461.767 299.393 457.557 304.615 457.557 310.645C457.557 317.855 463.631 323.728 471.085 323.728C474.172 323.728 477.08 322.74 479.504 320.887L480.492 320.134L480.47 320.123Z"
                    fill="#367D79"
                  />
                  <path
                    d="M482.21 298.832H478.842V340.842H482.21V298.832Z"
                    fill="#367D79"
                  />
                  <path
                    d="M488.284 298.281L479.345 306.964L481.691 309.381L490.631 300.697L488.284 298.281Z"
                    fill="#367D79"
                  />
                  <path
                    d="M473.283 306.712L470.937 309.129L479.354 317.303L481.7 314.886L473.283 306.712Z"
                    fill="#367D79"
                  />
                  <path
                    d="M507.884 339.157H403.862V342.526H507.884V339.157Z"
                    fill="#367D79"
                  />
                  <path
                    d="M429.873 325.379C418.501 325.379 408.117 318.45 404.02 308.141L407.152 306.895C410.744 315.934 419.871 322.01 429.873 322.01C442.099 322.01 452.483 313.161 454.01 301.426L457.344 301.864C455.593 315.261 443.783 325.367 429.862 325.367L429.873 325.379Z"
                    fill="#367D79"
                  />
                  <path
                    d="M407.152 289.971L404.02 288.725C408.117 278.416 418.501 271.487 429.873 271.487C432.691 271.487 435.464 271.892 438.125 272.7L437.148 275.923C434.802 275.216 432.354 274.856 429.873 274.856C419.871 274.856 410.744 280.932 407.152 289.971Z"
                    fill="#367D79"
                  />
                  <path
                    d="M455.873 303.683L455.054 303.222C443.176 296.552 435.79 284.132 435.79 270.803C435.79 257.473 443.165 245.053 455.054 238.383L455.873 237.922L456.693 238.383C468.57 245.053 475.957 257.473 475.957 270.803C475.957 272.521 475.833 274.261 475.598 275.957L472.263 275.485C472.477 273.947 472.589 272.375 472.589 270.803C472.589 259 466.201 247.973 455.873 241.796C445.534 247.973 439.146 259.011 439.146 270.803C439.146 282.594 445.534 293.632 455.873 299.809C459.353 297.731 462.407 295.103 464.966 291.982L467.571 294.115C464.551 297.799 460.891 300.864 456.693 303.211L455.873 303.672V303.683Z"
                    fill="#367D79"
                  />
                  <path
                    d="M109.613 95.9235C109.175 95.9235 108.737 95.755 108.4 95.4182C107.749 94.7556 107.771 93.6888 108.434 93.0375L122.04 79.8202C122.714 79.1689 123.769 79.1913 124.42 79.8539C125.071 80.5164 125.049 81.5833 124.386 82.2346L110.78 95.4518C110.455 95.7663 110.028 95.9235 109.613 95.9235Z"
                    fill="#367D79"
                  />
                  <path
                    d="M168.595 38.6636C168.157 38.6636 167.719 38.4952 167.383 38.1583C166.731 37.4957 166.754 36.4289 167.416 35.7776L181.022 22.5603C181.685 21.909 182.751 21.9315 183.402 22.594C184.053 23.2565 184.031 24.3234 183.369 24.9747L169.762 38.192C169.437 38.5064 169.01 38.6636 168.595 38.6636Z"
                    fill="#367D79"
                  />
                  <path
                    d="M123.23 38.6636C122.803 38.6636 122.388 38.5064 122.062 38.192L108.456 24.9747C107.794 24.3234 107.771 23.2678 108.423 22.594C109.074 21.9315 110.129 21.909 110.803 22.5603L124.409 35.7776C125.071 36.4289 125.093 37.4845 124.442 38.1583C124.117 38.4952 123.679 38.6636 123.23 38.6636Z"
                    fill="#367D79"
                  />
                  <path
                    d="M182.212 95.9235C181.786 95.9235 181.37 95.7663 181.045 95.4518L167.439 82.2346C166.776 81.5833 166.754 80.5277 167.405 79.8539C168.056 79.1913 169.111 79.1689 169.785 79.8202L183.391 93.0375C184.053 93.6888 184.076 94.7444 183.425 95.4182C183.099 95.755 182.661 95.9235 182.212 95.9235Z"
                    fill="#367D79"
                  />
                  <path
                    d="M163.105 100.977C162.454 100.977 161.826 100.595 161.556 99.9549L156.65 88.4445C156.28 87.5911 156.684 86.6029 157.537 86.2323C158.39 85.8617 159.378 86.266 159.749 87.1194L164.655 98.6298C165.025 99.4833 164.621 100.471 163.768 100.842C163.554 100.932 163.33 100.977 163.105 100.977Z"
                    fill="#367D79"
                  />
                  <path
                    d="M133.637 31.9034C132.985 31.9034 132.357 31.5216 132.087 30.8815L127.182 19.3711C126.811 18.5177 127.215 17.5294 128.068 17.1589C128.922 16.7883 129.909 17.1926 130.28 18.046L135.186 29.5564C135.556 30.4098 135.152 31.398 134.299 31.7686C134.086 31.8585 133.861 31.9034 133.637 31.9034Z"
                    fill="#367D79"
                  />
                  <path
                    d="M104.415 77.3721C103.752 77.3721 103.113 76.9791 102.854 76.3166C102.506 75.4519 102.922 74.4749 103.786 74.1268L115.641 69.3542C116.505 69.0061 117.482 69.4216 117.83 70.2862C118.178 71.1509 117.763 72.1279 116.898 72.476L105.043 77.2486C104.841 77.3272 104.628 77.3721 104.415 77.3721Z"
                    fill="#367D79"
                  />
                  <path
                    d="M175.555 48.7703C174.893 48.7703 174.253 48.3772 173.995 47.7147C173.647 46.85 174.062 45.873 174.926 45.5249L186.781 40.7523C187.646 40.4042 188.622 40.8197 188.97 41.6844C189.318 42.5491 188.903 43.526 188.039 43.8741L176.184 48.6467C175.982 48.7253 175.768 48.7703 175.555 48.7703Z"
                    fill="#367D79"
                  />
                  <path
                    d="M187.41 77.3721C187.197 77.3721 186.983 77.3384 186.781 77.2486L174.926 72.476C174.062 72.1279 173.647 71.1509 173.995 70.2862C174.343 69.4216 175.331 69.0061 176.184 69.3542L188.039 74.1268C188.903 74.4749 189.318 75.4519 188.97 76.3166C188.712 76.9679 188.072 77.3721 187.41 77.3721Z"
                    fill="#367D79"
                  />
                  <path
                    d="M116.27 48.7703C116.056 48.7703 115.843 48.7366 115.641 48.6467L103.786 43.8741C102.922 43.526 102.506 42.5491 102.854 41.6844C103.202 40.8197 104.179 40.4042 105.043 40.7523L116.898 45.5249C117.763 45.873 118.178 46.85 117.83 47.7147C117.572 48.366 116.932 48.7703 116.27 48.7703Z"
                    fill="#367D79"
                  />
                  <path
                    d="M128.72 100.977C128.495 100.977 128.27 100.932 128.057 100.842C127.204 100.483 126.8 99.4833 127.17 98.6298L132.076 87.1194C132.447 86.266 133.435 85.8617 134.288 86.2323C135.141 86.5916 135.545 87.5911 135.175 88.4445L130.269 99.9549C129.999 100.595 129.371 100.977 128.72 100.977Z"
                    fill="#367D79"
                  />
                  <path
                    d="M158.188 31.9033C157.964 31.9033 157.739 31.8584 157.526 31.7686C156.673 31.4092 156.269 30.4098 156.639 29.5563L161.545 18.046C161.915 17.1925 162.903 16.7995 163.756 17.1588C164.61 17.5182 165.014 18.5176 164.643 19.3711L159.737 30.8814C159.468 31.5215 158.839 31.9033 158.188 31.9033Z"
                    fill="#367D79"
                  />
                  <path
                    d="M145.918 111.768C144.986 111.768 144.234 111.016 144.234 110.084V90.1514C144.234 89.2194 144.986 88.467 145.918 88.467C146.85 88.467 147.602 89.2194 147.602 90.1514V110.084C147.602 111.016 146.85 111.768 145.918 111.768Z"
                    fill="#367D79"
                  />
                  <path
                    d="M145.918 29.5339C144.986 29.5339 144.234 28.7815 144.234 27.8495V7.91687C144.234 6.98481 144.986 6.23242 145.918 6.23242C146.85 6.23242 147.602 6.98481 147.602 7.91687V27.8495C147.602 28.7815 146.85 29.5339 145.918 29.5339Z"
                    fill="#367D79"
                  />
                  <path
                    d="M113.834 60.6849H93.3009C92.3691 60.6849 91.6169 59.9325 91.6169 59.0005C91.6169 58.0684 92.3691 57.316 93.3009 57.316H113.834C114.765 57.316 115.518 58.0684 115.518 59.0005C115.518 59.9325 114.765 60.6849 113.834 60.6849Z"
                    fill="#367D79"
                  />
                  <path
                    d="M198.524 60.6849H177.991C177.06 60.6849 176.307 59.9325 176.307 59.0005C176.307 58.0684 177.06 57.316 177.991 57.316H198.524C199.456 57.316 200.208 58.0684 200.208 59.0005C200.208 59.9325 199.456 60.6849 198.524 60.6849Z"
                    fill="#367D79"
                  />
                  <path
                    d="M161.736 72.2964C161.736 73.4418 160.781 74.3738 159.591 74.3738C158.401 74.3738 157.447 73.4418 157.447 72.2964C157.447 71.1509 158.401 70.2189 159.591 70.2189C160.781 70.2189 161.736 71.1509 161.736 72.2964Z"
                    fill="#6D6E70"
                  />
                  <path
                    d="M159.603 74.9353C158.11 74.9353 156.908 73.7562 156.908 72.2963C156.908 70.8365 158.121 69.6574 159.603 69.6574C161.085 69.6574 162.297 70.8365 162.297 72.2963C162.297 73.7562 161.085 74.9353 159.603 74.9353ZM159.603 70.7803C158.727 70.7803 158.02 71.4653 158.02 72.2963C158.02 73.1273 158.727 73.8123 159.603 73.8123C160.478 73.8123 161.186 73.1273 161.186 72.2963C161.186 71.4653 160.478 70.7803 159.603 70.7803Z"
                    fill="#367D79"
                  />
                  <path
                    d="M145.918 79.371C134.378 79.371 124.992 70.2301 124.992 59.0004C124.992 47.7708 134.378 38.6299 145.918 38.6299C157.459 38.6299 166.844 47.7708 166.844 59.0004C166.844 60.9319 166.563 62.841 166.024 64.6826C165.755 65.5698 164.823 66.0863 163.936 65.8168C163.049 65.5473 162.544 64.6152 162.802 63.7281C163.251 62.2009 163.487 60.6175 163.487 59.0117C163.487 49.6349 155.606 42.01 145.918 42.01C136.23 42.01 128.349 49.6349 128.349 59.0117C128.349 68.3884 136.23 76.0245 145.918 76.0245C147.58 76.0245 149.23 75.8 150.813 75.3508C151.711 75.1037 152.631 75.6203 152.89 76.5187C153.148 77.417 152.62 78.3378 151.722 78.5961C149.847 79.1239 147.883 79.3934 145.918 79.3934V79.371Z"
                    fill="#367D79"
                  />
                  <path
                    d="M515.844 169.612C514.204 168.759 512.375 168.298 510.388 168.298C503.955 168.298 498.746 173.363 498.746 179.607C498.746 180.236 498.802 180.853 498.903 181.46C495.412 183.189 493.021 186.704 493.021 190.758C493.021 192.352 493.391 193.857 494.042 195.216C490.315 196.698 487.677 200.247 487.677 204.402C487.677 209.882 492.257 214.329 497.893 214.329H513.228"
                    fill="white"
                  />
                  <path
                    d="M513.239 216.002H497.904C491.337 216.002 486.004 210.791 486.004 204.402C486.004 200.213 488.272 196.44 491.898 194.374C491.528 193.206 491.337 191.993 491.337 190.758C491.337 186.569 493.515 182.751 497.096 180.539C497.073 180.224 497.062 179.91 497.062 179.607C497.062 172.442 503.035 166.625 510.388 166.625C512.599 166.625 514.699 167.131 516.618 168.13L515.069 171.117C513.632 170.365 512.06 169.994 510.388 169.994C504.898 169.994 500.43 174.306 500.43 179.618C500.43 180.134 500.475 180.662 500.565 181.19L500.778 182.425L499.655 182.976C496.602 184.48 494.716 187.467 494.716 190.769C494.716 192.072 495.008 193.318 495.569 194.486L496.366 196.114L494.682 196.788C491.46 198.068 489.383 201.055 489.383 204.413C489.383 208.961 493.212 212.655 497.927 212.655H513.262V216.024L513.239 216.002Z"
                    fill="#367D79"
                  />
                  <path
                    d="M540.945 216.002H513.25C509.782 216.002 506.515 214.688 504.056 212.296C501.586 209.893 500.228 206.704 500.228 203.301C500.228 198.675 502.765 194.497 506.818 192.262C506.38 190.949 506.155 189.567 506.155 188.152C506.155 183.526 508.58 179.315 512.554 176.9C512.521 176.519 512.509 176.137 512.509 175.766C512.509 167.917 519.066 161.538 527.115 161.538C535.164 161.538 541.72 167.917 541.72 175.766C541.72 176.137 541.709 176.519 541.675 176.9C545.649 179.315 548.074 183.526 548.074 188.152C548.074 189.567 547.85 190.937 547.412 192.262C551.464 194.497 554.001 198.675 554.001 203.301C554.001 210.297 548.153 216.002 540.979 216.002H540.945ZM527.092 164.918C520.895 164.918 515.855 169.792 515.855 175.777C515.855 176.361 515.911 176.956 516.012 177.552L516.225 178.787L515.103 179.337C511.656 181.044 509.512 184.424 509.512 188.152C509.512 189.624 509.838 191.038 510.478 192.364L511.275 193.992L509.591 194.666C505.942 196.114 503.596 199.494 503.596 203.301C503.596 205.783 504.595 208.119 506.402 209.882C508.232 211.656 510.657 212.633 513.25 212.633H540.945C546.278 212.633 550.611 208.444 550.611 203.301C550.611 199.494 548.254 196.114 544.616 194.666L542.932 193.992L543.73 192.364C544.369 191.038 544.695 189.624 544.695 188.152C544.695 184.413 542.551 181.044 539.104 179.337L537.982 178.787L538.195 177.552C538.296 176.956 538.352 176.361 538.352 175.777C538.352 169.792 533.312 164.918 527.115 164.918H527.092Z"
                    fill="#367D79"
                  />
                  <path
                    d="M528.776 181.718H525.408V226.715H528.776V181.718Z"
                    fill="#367D79"
                  />
                  <path
                    d="M512.072 214.317H508.704V226.715H512.072V214.317Z"
                    fill="#367D79"
                  />
                  <path
                    d="M520.361 184.715L518.015 187.132L525.901 194.789L528.247 192.372L520.361 184.715Z"
                    fill="#367D79"
                  />
                  <path
                    d="M533.742 184.648L525.857 192.307L528.204 194.724L536.088 187.065L533.742 184.648Z"
                    fill="#367D79"
                  />
                  <path
                    d="M518.123 195.647L515.777 198.064L525.902 207.896L528.248 205.479L518.123 195.647Z"
                    fill="#367D79"
                  />
                  <path
                    d="M535.986 195.576L525.863 205.409L528.209 207.826L538.332 197.992L535.986 195.576Z"
                    fill="#367D79"
                  />
                  <path
                    d="M538.835 228.399H516.921C515.989 228.399 515.237 227.647 515.237 226.715C515.237 225.783 515.989 225.031 516.921 225.031H538.835C539.767 225.031 540.519 225.783 540.519 226.715C540.519 227.647 539.767 228.399 538.835 228.399Z"
                    fill="#367D79"
                  />
                  <path
                    d="M483.4 378.843H273.111C272.179 378.843 271.427 378.09 271.427 377.158C271.427 376.226 272.179 375.474 273.111 375.474H481.75C482.659 353.621 501.238 336.114 523.938 336.114C528.383 336.114 532.75 336.788 536.96 338.113C536.96 338.023 536.96 337.944 536.96 337.855C536.96 315.946 555.304 298.113 577.846 298.113C579.878 298.113 581.932 298.27 583.975 298.562C591.138 271.824 615.723 253.284 644.327 253.284C645.259 253.284 646.011 254.037 646.011 254.969C646.011 255.901 645.259 256.653 644.327 256.653C616.745 256.653 593.113 274.834 586.86 300.875C586.647 301.74 585.805 302.302 584.918 302.133C582.572 301.695 580.192 301.482 577.846 301.482C557.156 301.482 540.328 317.799 540.328 337.855C540.328 338.607 540.362 339.416 540.429 340.404C540.474 340.976 540.216 341.527 539.755 341.875C539.295 342.212 538.689 342.302 538.15 342.099C533.603 340.37 528.832 339.494 523.938 339.494C502.518 339.494 485.084 356.395 485.084 377.17C485.084 378.102 484.332 378.854 483.4 378.854V378.843Z"
                    fill="#367D79"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2512_1838">
                    <rect width="646" height="379" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </ClientContentContainer>
      </div>
      <div>
        <ClientContentContainer>
          <Card className="mx-auto max-w-5xl rounded-xl bg-gray-200 p-4">
            <div className={"text-center"}>
              <span className="text-sm font-bold uppercase text-[#2F7985]">
                How does it work?
              </span>
              <h2 className="mt-4 mb-16 font-serif text-4xl text-gray-600">
                Here is what you can expect
              </h2>
            </div>
            <div className="mx-auto flex justify-between space-x-4 text-center">
              <div className="flex flex-col items-center space-y-4 flex-1">
                <div className="h-32 w-32 rounded-full bg-white p-8">
                  <ServiceKindIcon
                    withAccent
                    serviceKind={"pre-purchase counseling"}
                  />
                </div>
                <div className="font-lora text-base xl:text-lg font-semibold text-gray-500">
                  Get help for free
                </div>
                <div>
                  <p className={"font-poppins text-sm text-charcoal-600"}>
                    Whether you're seeking educational materials, professional
                    advice, or tools to help you make informed decisions about
                    buying a home, we’ve got you covered.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4 flex-1">
                <div className="h-32 w-32 rounded-full bg-white p-6">
                  <ServiceKindIcon
                    withAccent
                    serviceKind={"whrc portal path"}
                  />
                </div>
                <div className="font-lora text-base xl:text-lg font-semibold text-gray-500">
                  Find the best path for you
                </div>
                <div>
                  <p className={"font-poppins text-sm text-charcoal-600"}>
                    Take our assessment and let's build a personalized path to
                    help you reach your homeownership goals.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4 flex-1">
                <div className="h-32 w-32 rounded-full bg-white p-8">
                  <ServiceKindIcon withAccent serviceKind={"homeowner"} />
                </div>
                <div className="font-lora text-base xl:text-lg font-semibold text-gray-500">
                  Build your homeownership team
                </div>
                <div>
                  <p className={"font-poppins text-sm text-charcoal-600"}>
                    From experienced real estate pros to helpful mortgage
                    lenders and HUD-certified counselors, handpick the perfect
                    partners to turn your dream of homeownership into reality.
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </ClientContentContainer>
      </div>
    </>
  )
}

export default Landing
